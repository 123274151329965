import { styled, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Image from 'next/image';
import IconButton from '@material-ui/core/IconButton';

export const BoxWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 680,
  background: '#fff',
  boxShadow: '0 0 6px #0000001D',
  borderRadius: 13,
  padding: 20,
}));

export const Container = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: 20,
  width: '100%',
  fontFamily: 'Barlow',
  overflowX: 'hidden',
  overflowY: 'hidden',
  '-webkit-overflow-scrolling': 'touch',
  height: '100%',
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
  },
  '& .eventCardTitle': {
    padding: '0 10px',
    '@media screen and (min-width: 1100px)': {
      padding: '30px 0 20px',
      '&.eventCardTitleFirstChild': {
        padding: '0 0 20px',
      },
    },
  },
  '@media screen and (max-width: 780px)': {
    margin: '20px 10px',
  },
}));

export const CardContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  overflowY: 'hidden',
  overflowX: 'scroll',
  marginBottom: 40,
  padding: '10px 30px 0 0',
  '@media screen and (min-width: 1100px)': {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'initial',
    marginBottom: 'initial',
    marginLeft: 'initial',
    padding: '10px 0 0',
  },
}));

export const CardLink = styled('div')(() => ({
  backgroundColor: '#fff',
  textDecoration: 'none',
  borderRadius: 13,
  boxShadow: '0 0 6px #0000001D',
  width: 244,
  minWidth: 244,
  marginRight: 20,
  '@media screen and (min-width: 1100px)': {
    width: '48%',
    maxWidth: 694,
    height: 284,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    '&:last-child': {
      marginRight: 0,
    },
  },
  '@media screen and (min-width: 1600px)': {
    marginRight: 50,
    marginBottom: 60,
  },
}));

export const CardLinkGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '310px',
  padding: '20px 8px',
  textAlign: 'center',
  '@media screen and (min-width: 1100px)': {
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: 'calc(100% - 290px)',
    padding: '20px 20px 30px 0',
    textAlign: 'initial',
  },
  '& h3, h4, h5': {
    margin: 0,
  },
  '& h3': {
    textTransform: 'uppercase',
    fontSize: '1.06rem',
    padding: '10px 0 0',
    minHeight: 60,
    '@media screen and (min-width: 1370px)': {
      fontSize: '1.3rem',
    },
    '@media screen and (min-width: 1600px)': {
      fontSize: '1.525rem',
      minHeight: 68,
    },
  },
  '& h4': {
    fontSize: '0.68rem',
    fontWeight: 'normal',
    '@media screen and (min-width: 1370px)': {
      fontSize: '0.9rem',
    },
    '@media screen and (min-width: 1600px)': {
      fontSize: '0.925rem',
    },
  },
  '& h5': {
    fontSize: '0.68rem',
    fontWeight: 'bold',
    paddingBottom: 8,
    '@media screen and (min-width: 1370px)': {
      fontSize: '0.85rem',
    },
  },
  '& button': {
    borderRadius: 13,
    height: 40,
    marginBottom: 10,

    '@media screen and (min-width: 1100px)': {
      marginBottom: 0,
    },
  },
}));

export const CardLinkGroupButtons = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  '@media screen and (min-width: 1100px)': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& button': {
    width: 147,
    '@media screen and (min-width: 1370px)': {
      width: 176,
    },
  },
  '& button span': {
    fontSize: '0.62rem',
    '@media screen and (min-width: 1370px)': {
      fontSize: '0.81rem',
    },
  },
  '& a': {
    color: '#0080ED',
    fontSize: '0.68rem',
    textDecoration: 'underline',
    '@media screen and (min-width: 1370px)': {
      fontSize: '0.81rem',
    },
  },
}));

export const ContainerRegistered = styled('div')({
  '@media screen and (min-width: 1100px)': {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 20,
    overflowX: 'scroll',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    height: '100%',
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
  },
});

export const Img = styled(Image)({
  maxWidth: '35vw',
  borderTopLeftRadius: 13,
  borderTopRightRadius: 13,
  width: '100%',
  '@media screen and (min-width: 1100px)': {
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 13,
    width: 290,
    height: 284,
  },
});

export const ShareButton = withStyles(() => ({
  root: {
    position: 'absolute',
    zIndex: 99,
    right: 0,
    cursor: 'pointer',
    '& svg': {
      fill: 'white',
      width: 30,
      height: 30,
    },
  },
}))(IconButton);

export const ModalLogo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 40,
  '& img': {
    width: 90,
  },
}));

export const CenterButton = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px 0 30px',
  fontFamily: 'Barlow',
  '& button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 13,
    height: 50,
    fontSize: 20,
    fontFamily: 'Barlow, sans-serif',
    '@media screen and (min-width: 1100px)': {
      width: 278,
    },
  },
}));

export const CloseButton = withStyles(() => ({
  root: {
    position: 'absolute',
    top: '2%',
    right: '2%',
    fontWeight: 700,
    zIndex: 9,

    '@media screen and (min-width: 1100px)': {
      top: '4%',
      right: '2%',
    },
  },
}))(IconButton);

export const SpeakersInfo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 8,
}));

export const SpeakersName = styled('p')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  display: '-webkit-box',
  fontSize: '0.68rem',
  minHeight: '0.68rem',
}));
